import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import bonusImage from '../assets/hero-welcome-bonus.jpg';  // Görseli import ettik
import MaskedInput from 'react-text-mask';

// Styled Components for Modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  max-width: 500px;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h2`
  font-size: 1.75rem;
  color: ${(props) => (props.success ? 'green' : 'red')};
`;

const ModalMessage = styled.p`
  margin-top: 1rem;
  font-size: 1.25rem;
  color: black
`;

// Main Hero Component
const Hero = ({ setIsSubmitted }) => {
  const url = window.location
  const params = new URLSearchParams(url.search);
  const utmSource = params.get('utm_source');
  const utmMedium = params.get('utm_medium');

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const fullName = event.target.fullName.value.trim();
    const phoneNumber = event.target.phone.value.trim();

    // İsim ve Soyisim ayrıştırması
    const nameArray = fullName.split(' ');
    const lastName = nameArray.pop(); // Soyad
    const firstName = nameArray.join(' '); // Geri kalan adlar

    const cleanedPhoneNumber = phoneNumber
      .replace(/[() ]/g, "");

    const userData = {
      firstName: firstName,
      lastName: lastName,
      email: 'lp@highexchange.co',  // Sabit email
      phoneNumber: cleanedPhoneNumber,
      UtmSource: utmSource ? utmSource : ".",
      UtmMedium: utmMedium ? utmMedium : "."
    };

    try {
      const response = await axios.post('https://highexchange.co/api/Form/SubmitForm', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setIsSubmitted(true);
    } catch (error) {
      setModalMessage('Form gönderilirken bir hata oluştu.');
      setModalSuccess(false);
      setModalOpen(true);
    }

    // Modal'ı 3 saniye sonra otomatik kapat
    setTimeout(() => setModalOpen(false), 3000);
  };

  return (
    <section
      id="hero"  // Smooth scroll için ID ekliyoruz
      className="bg-cover bg-center min-h-screen text-white flex flex-col justify-center items-center p-4"
      style={{ backgroundColor: '#1F2937' }}
    >
      {/* Başlık */}
      <motion.h1
        className="text-3xl md:text-5xl font-bold mb-8 text-center max-w-3xl text-slate-950"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2, ease: "easeOut" }}
        whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
      >
        Kripto Para Dünyasına Güvenli Bir Giriş Yapın! Şimdi kaydolun ve benzersiz fırsatlarla ticarete başlayın.
      </motion.h1>

      {/* Kampanyalar Yan Yana */}
      <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4 mb-8">
        <motion.div
          className="relative bg-yellow-400 text-gray-800 p-6 rounded-lg text-center w-full md:w-80"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
        >
          {/* Görsel */}
          <img src={bonusImage} alt="Hoş Geldin Bonusu" className="absolute top-0 left-0 w-full h-full object-cover rounded-lg opacity-20" />

          <h2 className="relative text-2xl font-bold z-10">🎁 Hoş Geldin Bonusu!</h2>
          <p className="relative z-10">İlk $100 yatırmanıza ek olarak $50 bonus kazanın!</p>
        </motion.div>
        <motion.div
          className="relative bg-yellow-400 text-gray-800 p-6 rounded-lg text-center w-full md:w-80"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
        >
          {/* Görsel */}
          <img src={bonusImage} alt="Ücretsiz İşlem Fırsatı" className="absolute top-0 left-0 w-full h-full object-cover rounded-lg opacity-20" />

          <h2 className="relative text-2xl font-bold z-10">🔥 Ücretsiz İşlem Fırsatı!</h2>
          <p className="relative z-10">Kayıttan sonraki ilk 30 gün boyunca işlem ücreti ödemeden ticaret yapın!</p>
        </motion.div>
      </div>

      {/* İsim Soyisim ve Telefon Formu */}
      <motion.form
        className="bg-gray-700 p-6 rounded-lg text-gray-200 w-full max-w-4xl"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
        onSubmit={handleSubmit}
      >
        <h3 className="text-xl font-bold mb-4 text-center">Erken Erişim İçin Kaydolun</h3>
        <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1">
            <motion.label
              className="block text-gray-300 text-sm mb-2"
              htmlFor="fullName"
              whileHover={{ scale: 1.05 }}
            >
              İsim Soyisim
            </motion.label>
            <motion.input
              className="w-full p-2 rounded-lg bg-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
              type="text"
              id="fullName"
              name="fullName"
              placeholder="İsim Soyisim girin"
              whileFocus={{ scale: 1.05, transition: { duration: 0.3 } }}
              required
            />
          </div>
          <div className="flex-1">
            <motion.label
              className="block text-gray-300 text-sm mb-2"
              htmlFor="phone"
              whileHover={{ scale: 1.05 }}
            >
              Telefon Numarası
            </motion.label>
            <MaskedInput
              mask={[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]}
              render={(ref, props) => (
                <motion.input
                  ref={ref}
                  {...props}
                  className="w-full p-2 rounded-lg bg-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Telefon numarası girin"
                  whileFocus={{ scale: 1.05, transition: { duration: 0.3 } }}
                  required
                />
              )}
            />
          </div>
        </div>
        <motion.button
          className="w-full bg-yellow-400 text-gray-800 p-2 rounded-lg mt-4 hover:bg-yellow-500 transition duration-300"
          type="submit"
          whileHover={{ scale: 1.05, backgroundColor: "#f59e0b", transition: { duration: 0.3 } }}
        >
          Kaydol
        </motion.button>
      </motion.form>

      {/* Modal (Başarı veya Hata) */}
      {modalOpen && (
        <ModalOverlay>
          <ModalContent
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <ModalTitle success={modalSuccess}>
              {modalSuccess ? 'Başarılı!' : 'Hata!'}
            </ModalTitle>
            <ModalMessage>{modalMessage}</ModalMessage>
          </ModalContent>
        </ModalOverlay>
      )}
    </section>
  );
};

export default Hero;