import React from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-scroll';

// Styled Components
const FooterContainer = styled.footer`
  background-color: #1F2937;
  color: white;
  padding: 2rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const FooterText = styled.p`
  color: #E2E8F0;
  font-size: 1rem;
  margin: 0;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
`;

const SocialIconLink = styled.a`
  color: #FACC15;
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #fcd34d;
  }
`;

const FooterNav = styled.nav`
  margin-top: 1rem;
`;

const FooterLink = styled.a`
  color: #E2E8F0;
  margin: 0 1rem;
  font-size: 1rem;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #FACC15;
  }
`;

const Copyright = styled.div`
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #CBD5E0;
`;

const Footer = () => {
  return (
    <FooterContainer>
      {/* <FooterText>Bize Ulaşın</FooterText>
      <SocialIcons>
        <SocialIconLink href="https://facebook.com" target="_blank" aria-label="Facebook">
          <FaFacebookF />
        </SocialIconLink>
        <SocialIconLink href="https://twitter.com" target="_blank" aria-label="Twitter">
          <FaTwitter />
        </SocialIconLink>
        <SocialIconLink href="https://instagram.com" target="_blank" aria-label="Instagram">
          <FaInstagram />
        </SocialIconLink>
      </SocialIcons> */}
      <FooterNav>
        <Link
          to="features"  // Hero bölümüne gidecek
          smooth={true}
          duration={500}
          style={{margin: "0 .7rem"}}
          className="text-gray-300 hover:text-yellow-400 cursor-pointer transition-colors duration-300"
        >
          Hakkımızda
        </Link>
        <Link
          to="campaigns"  // Hero bölümüne gidecek
          smooth={true}
          duration={500}
          style={{margin: "0 .7rem"}}
          className="text-gray-300 hover:text-yellow-400 cursor-pointer transition-colors duration-300"
        >
          Hizmetler
        </Link>
        <Link
          to="hero"  // Hero bölümüne gidecek
          smooth={true}
          duration={500}
          style={{margin: "0 .7rem"}}
          className="text-gray-300 hover:text-yellow-400 cursor-pointer transition-colors duration-300"
        >
          İletişim
        </Link>
      </FooterNav>
      <Copyright>&copy; 2024 High Exchange. Tüm Hakları Saklıdır.</Copyright>
    </FooterContainer>
  );
};

export default Footer;