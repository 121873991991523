import React from 'react';
import styled from 'styled-components';
import { FaUserCircle } from 'react-icons/fa';
import { Element } from 'react-scroll';  // react-scroll kullanarak scroll efekti ekliyoruz

// Styled Components
const Section = styled.section`
  background-color: #1F2937;
  color: white;
  padding: 4rem 2rem;
  text-align: center;
`;

const ReviewsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
`;

const ReviewBox = styled.div`
  background-color: ${({ isRight }) => (isRight ? '#2D3748' : '#4A5568')};
  padding: 1.5rem;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: ${({ isRight }) => (isRight ? 'flex-start' : 'flex-end')};
  position: relative;
  max-width: 75%;
  margin-left: ${({ isRight }) => (isRight ? '0' : 'auto')};
  margin-right: ${({ isRight }) => (isRight ? 'auto' : '0')};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    ${({ isRight }) => (isRight ? 'left: -10px;' : 'right: -10px;')}
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: ${({ isRight }) => (isRight ? '#2D3748' : '#4A5568')};
    border-bottom: 0;
    margin-top: -10px;
    ${({ isRight }) => (isRight ? 'border-right: 0;' : 'border-left: 0;')}
  }
`;

const ReviewText = styled.p`
  font-size: 1rem;
  color: #CBD5E0;
  line-height: 1.5;
  margin-bottom: 1rem;
`;

const Reviewer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const Icon = styled(FaUserCircle)`
  font-size: 2rem;
  color: #FACC15;
  margin-right: 0.75rem;
`;

const ReviewerName = styled.h4`
  font-size: 1rem;
  color: #E2E8F0;
`;

const ScrollableReviews = styled(Element)`
  max-height: 400px;
  overflow-y: auto;
  padding-right: 1rem;

  /* Custom scroll bar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #2D3748;
  }

  &::-webkit-scrollbar-thumb {
    background: #FACC15;
    border-radius: 10px;
  }
`;

// Kullanıcı Yorumları
const reviews = [
  {
    text: 'Bu platformu kullanmaya başladım ve ilk günden itibaren tüm işlemlerim sorunsuz ilerledi. Hızlı ve güvenilir, şiddetle tavsiye ederim!',
    name: 'Ali K.',
    isRight: true,
  },
  {
    text: 'İlk başta biraz tedirgindim ama destek ekibi her konuda yardımcı oldu. Şu an güvenle kullanıyorum.',
    name: 'Merve T.',
    isRight: false,
  },
  {
    text: 'Platformun arayüzü çok kullanıcı dostu. İşlemler hızlı ve güvenli. Yeni başlayanlar için de harika bir seçenek!',
    name: 'Canan Y.',
    isRight: true,
  },
  {
    text: 'Kripto ticareti için ideal bir platform, özellikle hızlı işlem yapabilme kabiliyeti çok hoşuma gitti!',
    name: 'Selim A.',
    isRight: false,
  },
  {
    text: 'Uzun zamandır aradığım güvenilir platformu sonunda buldum. Herkese tavsiye ediyorum!',
    name: 'Cem O.',
    isRight: true,
  },
];

const UserReviews = () => {
  return (
    <Section>
      <h2 className="text-3xl font-bold mb-8">Kullanıcı Yorumları</h2>
      <ScrollableReviews>
        <ReviewsContainer>
          {reviews.map((review, index) => (
            <ReviewBox key={index} isRight={review.isRight}>
              <ReviewText>{review.text}</ReviewText>
              <Reviewer>
                <Icon />
                <ReviewerName>{review.name}</ReviewerName>
              </Reviewer>
            </ReviewBox>
          ))}
        </ReviewsContainer>
      </ScrollableReviews>
    </Section>
  );
};

export default UserReviews;