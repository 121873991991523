import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Styled Components
const Section = styled.section`
  background-color: #1F2937;
  color: white;
  padding: 4rem 2rem;
  text-align: center;
`;

const ContentContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: left;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #FACC15;  // Sarı tonunu kullandık
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.7;
  color: #E2E8F0;
`;

const HighlightedText = styled.span`
  color: #FACC15;  // Sarı vurgusu
  font-weight: bold;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
`;

const ListItem = styled.li`
  background-color: #2D3748;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '✔';
    color: #FACC15;
    font-size: 1.5rem;
    margin-right: 1rem;
  }
`;

const ListItemText = styled.p`
  font-size: 1rem;
  color: #E2E8F0;
  margin: 0;
`;

const CTAButton = styled(motion.button)`
  padding: 1rem 2rem;
  font-size: 1.25rem;
  color: #1F2937;
  background-color: #FACC15;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  align-self: center;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #fcd34d;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

// Smooth scroll function
const scrollToHero = () => {
  const heroSection = document.getElementById('hero');
  if (heroSection) {
    heroSection.scrollIntoView({ behavior: 'smooth' });
  }
};

const EasyDeposit = () => {
  return (
    <Section>
      <ContentContainer>
        <Title>Para Yatırmak Hiç Bu Kadar Kolay Olmamıştı</Title>
        <Paragraph>
          Platformumuzda <HighlightedText>para yatırmak</HighlightedText> sadece birkaç tıklama kadar kolay. Güvenli altyapımız sayesinde,
          işlemlerinizi hızlıca gerçekleştirebilirsiniz. İster banka havalesi, ister kredi kartı kullanarak, 
          istediğiniz yöntemle <HighlightedText>anında yatırım</HighlightedText> yapabilirsiniz.
        </Paragraph>
        <Paragraph>
          Tüm yatırımlarınızı, platformumuzdaki <HighlightedText>hızlı ve güvenli işlem</HighlightedText> altyapısı ile gerçekleştirin.
          Para yatırma işlemleri birkaç dakika içinde tamamlanır ve yatırımlarınız hesaplarınıza güvenle geçer.
        </Paragraph>
        <List>
          <ListItem>
            <ListItemText>Bankanız üzerinden güvenle para yatırabilirsiniz.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Kredi kartınızı kullanarak hızlı işlem yapabilirsiniz.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Anında bildirim alarak yatırımlarınızı takip edin.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Destek ekibimiz, sorularınız için her zaman hazır.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>24/7 müşteri desteği ile her an yanınızdayız.</ListItemText> {/* Yeni özellik 1 */}
          </ListItem>
          <ListItem>
            <ListItemText>Yatırımlarınızı hızla gerçekleştirin ve fırsatları kaçırmayın.</ListItemText> {/* Yeni özellik 2 */}
          </ListItem>
        </List>
        <CTAButton
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={scrollToHero}  // Hero'ya scroll
        >
          Şimdi Başlayın
        </CTAButton>
      </ContentContainer>
    </Section>
  );
};

export default EasyDeposit;