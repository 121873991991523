import React from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { FaGift, FaBolt, FaStar } from 'react-icons/fa';
import CampaignsBg from '../assets/Campaigns-Bg.jpg';  // Görseli import ettik
import { useInView } from 'react-intersection-observer';

// Styled Components
const Section = styled.section`
  position: relative;
  padding: 4rem 0;
  background: black;
  color: white;
  overflow: hidden;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${CampaignsBg});
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: -1;
`;

const CampaignContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
`;

const CampaignTitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 3rem;
`;

const CampaignGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const CampaignCard = styled(motion.div)`
  background: rgba(20, 20, 20, 0.9);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
`;

const CampaignIcon = styled.div`
  font-size: 3rem;
  color: #FACC15;  // Daha açık sarı (#FACC15)
  margin-bottom: 1rem;
`;

const CampaignTitleText = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const CampaignDescription = styled.p`
  color: #ccc;
`;

const CTAButton = styled(motion.a)`
  display: inline-block;
  background-color: #FACC15;  // Sarı renk (#FACC15)
  color: black;
  padding: 0.75rem 2rem;
  font-size: 1.25rem;
  border-radius: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-top: 3rem;

  &:hover {
    background-color: #fcd34d;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.4);
  }
`;

// Kampanya Bilgileri (uzun detaylar ile)
const campaigns = [
  {
    icon: <FaGift />,
    title: 'Hoş Geldin Bonusu',
    description: 'Platformumuza katılın ve ilk yatırımınızı yapın. Bu bonus ile %50 daha fazla yatırım yapabileceksiniz. Yatırım deneyiminizi iyileştirirken aynı zamanda güvenli bir platformda işlem yapmanın keyfini çıkarın. Bu fırsat sadece yeni kullanıcılarımız için geçerlidir, acele edin!',
  },
  {
    icon: <FaBolt />,
    title: 'Hızlı ve Ücretsiz İşlemler',
    description: 'İlk 30 gün boyunca platformumuzda işlem ücreti ödemeden işlem yapabilirsiniz. Hızlı, güvenilir ve kullanıcı dostu sistemimiz sayesinde işlem hızının keyfini çıkarın. Bu özel kampanya süresi dolmadan işlem yapmaya başlayın ve tasarruf edin!',
  },
  {
    icon: <FaStar />,
    title: 'Sadakat Programı',
    description: 'Platformumuzda daha fazla işlem yaparak sadakat puanları kazanın. Bu puanlarla özel ödüller, indirimler ve VIP hizmetlerden faydalanabilirsiniz. Sadakat programı ile uzun vadeli kullanıcılarımıza sürekli avantajlar sunuyoruz.',
  },
];

// Smooth scroll function
const scrollToHero = () => {
  const heroSection = document.getElementById('hero');
  if (heroSection) {
    heroSection.scrollIntoView({ behavior: 'smooth' });
  }
};

const Campaigns = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,  // Görünürlüğü tetikleme oranı
    triggerOnce: false,  // Her scroll'da tekrar tetiklenmesini sağlar
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <Section ref={ref}>
      {/* Arka Plan */}
      <Background />

      {/* İçerik */}
      <CampaignContainer>
        <CampaignTitle
          initial={{ opacity: 0, y: 200 }}  // Daha aşağıdan gelsin
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: 200 },  // Daha aşağıdan başlıyor
            visible: { opacity: 1, y: 0 },   // Yukarı doğru geliyor
          }}
          transition={{ duration: 1 }}
        >
          Eşsiz Kampanyalarımıza Katılın
        </CampaignTitle>

        {/* Kampanyalar */}
        <CampaignGrid>
          {campaigns.map((campaign, index) => (
            <CampaignCard
              key={index}
              initial={{ opacity: 0, y: 200 }}  // Daha aşağıdan başlıyor
              animate={controls}
              variants={{
                hidden: { opacity: 0, y: 200, opacity: 0 },  // Yukarı doğru kaybolma efekti
                visible: { opacity: 1, y: 0 },   // Yukarı doğru geliyor
              }}
              transition={{ duration: 0.5, delay: index * 0.3 }}  // Adım adım geçiş efekti
            >
              <CampaignIcon>{campaign.icon}</CampaignIcon>
              <CampaignTitleText>{campaign.title}</CampaignTitleText>
              <CampaignDescription>{campaign.description}</CampaignDescription>
            </CampaignCard>
          ))}
        </CampaignGrid>

        {/* CTA Buton */}
        <CTAButton
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={scrollToHero}  // Smooth scroll fonksiyonu
        >
          Kampanyalara Katıl
        </CTAButton>
      </CampaignContainer>
    </Section>
  );
};

export default Campaigns;