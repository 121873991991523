import React from 'react';
import { motion } from 'framer-motion';
import { FaShieldAlt, FaTachometerAlt, FaMobileAlt, FaLock } from 'react-icons/fa';
import FeaturesImage from '../assets/FeaturesImage.png';  // Görseli import ettik

const features = [
  {
    icon: <FaShieldAlt className="text-yellow-400 text-3xl" />,
    title: 'Güvenli Altyapı',
    description: 'En son teknoloji güvenlik önlemleri ile varlıklarınızı koruyun.',
  },
  {
    icon: <FaTachometerAlt className="text-yellow-400 text-3xl" />,
    title: 'Yüksek Hız',
    description: 'Anında işlem yapabilme özelliği ile hızlı ticaret deneyimi yaşayın.',
  },
  {
    icon: <FaMobileAlt className="text-yellow-400 text-3xl" />,
    title: 'Mobil Uyumlu',
    description: 'Her cihazda kusursuz çalışan platform ile her yerden erişim sağlayın.',
  },
  {
    icon: <FaLock className="text-yellow-400 text-3xl" />,
    title: 'Çok Katmanlı Güvenlik',
    description: 'Çok faktörlü kimlik doğrulama ve gelişmiş şifreleme teknikleri ile tam güvenlik.',
  },
];

const Features = () => {
  return (
    <section className="bg-gray-800 text-white py-20">
      <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center">
        {/* Sol tarafta özellikler */}
        <div className="lg:w-1/2">
          <motion.h2
            className="text-3xl md:text-4xl font-bold mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Teknolojik Üstünlüklerimiz
          </motion.h2>
          <div className="space-y-6">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="flex items-center space-x-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
              >
                <div>{feature.icon}</div>
                <div>
                  <h3 className="text-xl font-semibold">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Sağ tarafta görsel */}
        <div className="lg:w-1/2 mt-10 lg:mt-0 lg:ml-12">
          <motion.img
            src={FeaturesImage}
            alt="Features Image"
            className="w-full h-auto"  // Görselin boyutunu ayarladık, gölgeleri kaldırdık
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          />
        </div>
      </div>
    </section>
  );
};

export default Features;