import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Campaigns from './components/Campaigns';
import UserReviews from './components/UserReviews';
import EasyDeposit from './components/EasyDeposit';
import Footer from './components/Footer';

import ThankYou from "./assets/thankyou.svg"

const App = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  return (
    <div className="App">
      <Navbar />
      {isSubmitted ? (
        <div className="thank-you">
          <div className="thanks-card">
            <img src={ThankYou} alt="success" width="175px" />
            <h3>İşleminiz Başarıyla Tamamlandı.</h3>
            <p className="mb-0">Doldurduğunuz form başarıyla gönderilmiştir.</p>
            <div>
              <button onClick={() => setIsSubmitted(false)} className="bg-yellow-400 text-gray-800 px-5 py-3 rounded-lg mt-4 hover:bg-yellow-500 transition duration-300">Ana sayfaya dön</button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <section id="hero">
            <Hero setIsSubmitted={setIsSubmitted} />
          </section>
          <section id="features">
            <Features />
          </section>
          <section id="campaigns">
            <Campaigns />
          </section>
          <section id="reviews">
            <UserReviews />
          </section>
          <section id="easydeposit">
            <EasyDeposit />
          </section>
        </>
      )}
      <section id="contact">
        <Footer />
      </section>
    </div>
  );
}

export default App;