import React, { useState } from 'react';
import { FaBitcoin, FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-scroll';  // react-scroll'den Link component'ini ekliyoruz
import highExchangeLogo from '../assets/highExchangeLogo.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-900 p-4 text-white flex justify-between items-center shadow-md">
      <div className="flex items-center space-x-2">
        {/* Logo */}
        <img 
          src={highExchangeLogo} 
          alt="High Exchange Logo" 
          className="w-30 h-10"  // Boyutunu ayarlıyoruz
        />
        {/* <span className="ml-2 text-xl font-semibold tracking-wide hover:text-yellow-400 transition-colors duration-300">
          High Exchange
        </span> */}
      </div>

      {/* Hamburger Menu for Small Screens */}
      <div className="md:hidden">
        <button onClick={toggleMenu}>
          {isOpen ? <FaTimes className="text-2xl" /> : <FaBars className="text-2xl" />}
        </button>
      </div>

      {/* Menü - Gizlenecek ve Gösterilecek */}
      <ul
        className={`flex flex-col md:flex-row absolute md:relative top-16 md:top-0 left-0 md:left-auto w-full md:w-auto bg-gray-900 md:bg-transparent transition-all duration-500 md:space-x-8 space-y-4 md:space-y-0 md:p-0 p-4 z-10 ${isOpen ? 'block' : 'hidden'} md:flex`}
      >
        <li className="group relative">
          <Link
            to="hero"  // Hero bölümüne gidecek
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-yellow-400 cursor-pointer transition-colors duration-300"
          >
            Anasayfa
          </Link>
        </li>
        <li className="group relative">
          <Link
            to="features"  // Features bölümüne gidecek
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-yellow-400 cursor-pointer transition-colors duration-300"
          >
            Teknoloji
          </Link>
        </li>
        <li className="group relative">
          <Link
            to="campaigns"  // Campaigns bölümüne gidecek
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-yellow-400 cursor-pointer transition-colors duration-300"
          >
            Kampanyalar
          </Link>
        </li>
        <li className="group relative">
          <Link
            to="reviews"  // UserReviews bölümüne gidecek
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-yellow-400 cursor-pointer transition-colors duration-300"
          >
            Yorumlar
          </Link>
        </li>
        {/* <li className="group relative">
          <Link
            to="easydeposit"  // EasyDeposit bölümüne gidecek
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-yellow-400 cursor-pointer transition-colors duration-300"
          >
            Easy Deposit
          </Link>
        </li> */}
        {/* <li className="group relative">
          <Link
            to="contact"  // Contact (Footer) bölümüne gidecek
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-yellow-400 cursor-pointer transition-colors duration-300"
          >
            Contact
          </Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;